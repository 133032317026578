import React from "react";
import { Box } from "@mui/material";

const LoaderScreen = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {/* <div className="spinner-7"></div> */}
      <figure>
        <img src="public/images/logo/new-logo/logo-small.png" alt="" width="160" />
      </figure>
    </Box>
  );
};

export default LoaderScreen;
